import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import emailjs from "emailjs-com";

import airplane from "../assets/send.svg";



const useStyles = makeStyles((theme) => ({
  message: {
    border: `2px solid ${theme.palette.common.blue}`,
    marginTop: "5em",
    borderRadius: 5,
  },
  sendButton: {
    ...theme.typography.estimate,
    borderRadius: 50,
    height: 45,
    width: 245,
    fontSize: "1rem",
    backgroundColor: theme.palette.common.orange,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    [theme.breakpoints.down("sm")]: {
      height: 40,
      width: 225,
    },
  },
}));

export default function ContactUs() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailHelper, setEmailHelper] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false, color: "" });
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    emailjs.init("_v_VbzGtGgxWXIHWu");
  }, []);

  const onChange = (event) => {
    const { id, value } = event.target;
    if (id === "email") {
      setEmail(value);
      setEmailHelper(
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value)
          ? ""
          : "Invalid email"
      );
    } else if (id === "phone") {
      setPhone(value);
    }
  };

  const sendEstimate = () => {
    setLoading(true);
    const templateParams = { email, name, phone, message };
    emailjs
      .send("service_i83bcjt", "template_gg9lt9r", templateParams)
      .then(
        () => {
          setLoading(false);
          setAlert({ open: true, color: "#4BB543" });
          setAlertMessage("Message sent successfully!");

          // Reset form fields after successful submission
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setEmailHelper("");
        
        },
        (err) => {
          setLoading(false);
          setAlert({ open: true, color: "#FF3232" });
          setAlertMessage("Something went wrong! Please try again.");
          console.error(err);
        }
      );
  };

  const buttonContents = (
    <>
      Send Message
      <img src={airplane} alt="paper airplane" style={{ marginLeft: "1em" }} />
    </>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Typography align="center" variant="h4" gutterBottom>
        Request Your Free Consultation
        </Typography>
      </Grid>
      <Grid item style={{ width: matchesSM ? "90%" : "30%" }}>
        <TextField
          label="Name"
          id="name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ marginBottom: "0em" }}
        />
        <TextField
          label="Email"
          id="email"
          fullWidth
          value={email}
          helperText={emailHelper}
          error={emailHelper.length !== 0}
          onChange={onChange}
          style={{ marginBottom: "0em" }}
        />
        <TextField
          label="Phone"
          id="phone"
          fullWidth
          value={phone}
          onChange={onChange}
          style={{ marginBottom: "-3.5em" }}
        />
        <TextField
          InputProps={{ disableUnderline: true }}
          value={message}
          className={classes.message}
          multiline
          fullWidth
          rows={5}
          id="message"
          onChange={(e) => setMessage(e.target.value)}
          
        />
      </Grid>
      <Grid item style={{ marginTop: "2em" }}>
        <Button
          variant="contained"
          className={classes.sendButton}
          onClick={sendEstimate}
          disabled={!name || !message || !!emailHelper}
        >
          {loading ? <CircularProgress size={30} /> : buttonContents}
        </Button>
      </Grid>
      <Snackbar
        open={alert.open}
        autoHideDuration={4000}
        onClose={() => setAlert({ ...alert, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={alertMessage}
        ContentProps={{ style: { backgroundColor: alert.color } }}
      />
    </Grid>
  );
}
