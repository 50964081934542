import React from "react";
import Lottie from "react-lottie";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import vision from "../assets/vision.svg";
import CallToAction from "./ui/CallToAction";
import technologyAnimation from "../animations/technologyAnimation/data.json";
import bespoke from "../assets/BespokeVsOffTheShelf.png";


const useStyles = makeStyles(theme => ({
    rowContainer: {
        paddingLeft: "5em",
        paddingRight: "5em",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "1.5em",
            paddingRight: "1.5em"
        }
    }
}));

export default function BespokeVsOffTheShelf(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

    const defaultOptions = {
        loop: true,
        autoplay: false,
        animationData: technologyAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Grid container direction="column">
            <Grid 
    container 
    justifyContent="center" // Centers the content
>
            <Grid 
        item 
        className={classes.rowContainer} 
        style={{ width: "55%", maxWidth: "55vw", textAlign: "center" }} // Enforces width & centers text
    >

        


       <Typography variant="h6" paragraph>
  Bespoke vs Off‑the‑Shelf Software: A Guide for Small Business Owners
</Typography>

{/* Display Image Here */}
<img 
                    src={bespoke} 
                    alt="Bespoke VS Off-The-Shelf" 
                    style={{ width: "100%", maxWidth: "500px", marginTop: "20px" }} 
                />
                
<Typography paragraph>
  <strong>Bespoke software</strong> refers to custom-built applications developed to meet a company’s specific needs. In contrast, <strong>off-the-shelf software</strong> (often called Commercial Off-The-Shelf or COTS) is a ready-made solution available to a broad market, offering generic functionality out of the box. Small business owners must consider both options because software is a critical driver of productivity and growth. Choosing between a tailor-made system or a prepackaged tool can impact your budget, efficiency, and ability to address unique business challenges. It’s important to understand the differences so you can select the option that best aligns with your business goals.
</Typography>

<Typography variant="h6" paragraph>
  Cost Comparison
</Typography>
<Typography paragraph>
  Cost is often the first concern for small businesses when comparing bespoke and off-the-shelf solutions. Off-the-shelf software typically has a <strong>lower upfront cost</strong>, since it’s already developed and sold to many users. You can often purchase it at a fraction of the cost of building a custom solution. This makes off-the-shelf tools attractive for startups and small companies with limited budgets. However, these solutions usually come with <strong>recurring expenses</strong> such as subscription fees or licensing costs. Over time, those monthly or annual fees add up – so the total cost of ownership should be evaluated.
</Typography>

<Typography paragraph>
  Bespoke software, on the other hand, requires a <strong>higher initial investment</strong>. You’ll need to fund the design and development phases (and possibly purchase infrastructure to run it), which can be expensive. Yet, this upfront cost can translate into <strong>long-term value</strong>. With a custom solution, you typically <strong>own the software outright</strong>, avoiding ongoing license fees. If the system is well-designed to streamline your operations, it may deliver a strong return on investment over time. When considering cost, weigh immediate affordability against the software’s lifetime value.
</Typography>

<Typography variant="h6" paragraph>
  Customization & Flexibility
</Typography>
<Typography paragraph>
  One of the biggest differences between bespoke and off-the-shelf software is the level of customization. Bespoke solutions are built to fit <em>your</em> business like a tailored suit, offering features and workflows designed specifically for you. This means you have complete control over functionality and user experience, which can lead to major gains in efficiency and productivity.
</Typography>

<Typography paragraph>
  Off-the-shelf software, conversely, comes with a predefined set of features intended to serve a wide audience. These ready-made tools often include many functions, some of which you may never need, and they offer limited ability to tweak the software’s behavior. You might be able to configure certain settings or choose from available modules, but you <strong>cannot change core features</strong> beyond what the vendor allows. This can result in <em>feature gaps</em> (missing functionality you wish you had) or <em>feature bloat</em> (extras that you don’t need).
</Typography>

<Typography variant="h6" paragraph>
  Scalability & Future Growth
</Typography>
<Typography paragraph>
  It’s vital for a small business to think about the future. What happens if your company grows, or your needs evolve? Bespoke software offers greater <strong>scalability</strong> because it can be designed with your growth plans in mind. You have the freedom to expand the system, add new features, or support more users as your business demands. As your operations become more complex, your development team can modify or extend the custom software to accommodate those changes.
</Typography>

<Typography paragraph>
  Off-the-shelf software may offer some scaling options (for instance, moving to a higher pricing tier to get more features or user capacity), but <strong>there are limits</strong>. Because you don’t own the platform, you can only scale within the confines the vendor has set. If your needs outgrow what the product can handle, you might face an expensive migration to a different solution later.
</Typography>

<Typography variant="h6" paragraph>
  Ease of Implementation & Time to Market
</Typography>
<Typography paragraph>
  For many small businesses, how quickly they can get a software solution up and running is a crucial factor. Here, off-the-shelf software has a clear advantage. Because it’s pre-built and ready to use, an off-the-shelf application can often be <strong>deployed immediately or within a very short time</strong> frame. There’s no development cycle to wait for – you simply install or sign up and configure it.
</Typography>

<Typography paragraph>
  Bespoke software, in contrast, requires a development period that can range from weeks to months (or longer, depending on complexity). <strong>Custom development takes time</strong> – you’ll go through requirements gathering, design, coding, testing, and deployment. Unlike a pre-made tool that’s immediately deployable, a bespoke solution may take <strong>months to develop, test, and implement</strong>.
</Typography>

<Typography variant="h6" paragraph>
  Maintenance & Support
</Typography>
<Typography paragraph>
  Another critical consideration is how each type of software is maintained and supported over time. With off-the-shelf software, <strong>maintenance and support are handled by the vendor</strong>. This can be a relief for small businesses that don’t have an in-house IT team. The vendor is responsible for providing updates (including new features, improvements, and security patches) and usually offers customer support if you encounter issues.
</Typography>

<Typography paragraph>
  Bespoke software shifts the maintenance responsibility to you (and your development partners). Since the solution is your own, you’ll need to plan for ongoing <strong>updates, bug fixes, and technical support</strong>. Having direct access to the developers who built the system can actually make resolving issues faster – they will be intimately familiar with the codebase and can address problems or make changes quickly to suit your needs.
</Typography>

<Typography variant="h6" paragraph>
  Conclusion & Recommendations
</Typography>
<Typography paragraph>
  Deciding between bespoke and off-the-shelf software comes down to evaluating your <strong>business’s priorities and constraints</strong>. Off-the-shelf solutions excel in cost-effectiveness and speed: they have low upfront fees, quick implementation, and vendor-managed support, making them a practical choice for many small businesses, especially those with common needs or limited budgets.
</Typography>

<Typography paragraph>
  Bespoke software shines in customization and long-term adaptability: it’s built for your exact requirements, can scale with your growth, and can provide a competitive edge through unique features – albeit with higher initial cost and the responsibility of upkeep. Small business owners should start by clearly identifying their must-have requirements and goals. Consider your current needs and future vision – if an off-the-shelf tool aligns closely with your requirements, it’s likely the right starting point. If it falls short or you see big advantages in a custom approach, then bespoke development might be worth the investment.
</Typography>
    </Grid>
    </Grid>

            <Grid item>
                <CallToAction setValue={props.setValue} />
            </Grid>
        </Grid>
    );
}
