import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { makeStyles } from "@material-ui/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import logo from "../../assets/logo.svg";

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}





const useStyles = makeStyles(theme => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "3em",
    [theme.breakpoints.down("md")]: {
      marginBottom: "2em"
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1.25em"
    }
  },
  logo: {
    height: "8em",
    [theme.breakpoints.down("md")]: {
      height: "7em"
    },
    [theme.breakpoints.down("xs")]: {
      height: "5.5em"
    }
  },
  logoContainer: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  tabContainer: {
    marginLeft: "auto"
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    marginLeft: "25px"
  },
  button: {
    ...theme.typography.estimate,
    borderRadius: "50px",
    marginLeft: "50px",
    marginRight: "25px",
    height: "45px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    }
  },
  menu: {
    backgroundColor: theme.palette.common.blue,
    color: "white",
    borderRadius: "0px"
  },
  menuItem: {
    ...theme.typography.tab,
    opacity: 0.7,
    "&:hover": {
      opacity: 1
    }
  },
  drawerIcon: {
    height: "50px",
    width: "50px"
  },
  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  drawer: {
    backgroundColor: theme.palette.common.blue
  },
  drawerItem: {
    ...theme.typography.tab,
    color: "white",
    opacity: 0.7
  },
  drawerItemEstimate: {
    backgroundColor: theme.palette.common.orange
  },
  drawerItemSelected: {
    "& .MuiListItemText-root": {
      opacity: 1
    }
  },
  appbar: {
    zIndex: theme.zIndex.modal + 1
  }
}));

export default function Header(props) {
  const classes = useStyles();
  const theme = useTheme();
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const matches = useMediaQuery(theme.breakpoints.down("md"));


  const [servicesAnchorEl, setServicesAnchorEl] = useState(null);
const [blogAnchorEl, setBlogAnchorEl] = useState(null);
const [openServicesMenu, setOpenServicesMenu] = useState(false);
const [openBlogMenu, setOpenBlogMenu] = useState(false);



  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleChange = (e, newValue) => {
    props.setValue(newValue);
  };

  const handleClick = (event, menuType) => {
    if (menuType === "services") {
      setServicesAnchorEl(event.currentTarget);
      setOpenServicesMenu(true);
      setBlogAnchorEl(null);
      setOpenBlogMenu(false);
      props.setValue(1);
    } else if (menuType === "blog") {
      setBlogAnchorEl(event.currentTarget);
      setOpenBlogMenu(true);
      setServicesAnchorEl(null);
      setOpenServicesMenu(false);
      props.setValue(5);
    }
  };
  


  
  

  const handleMenuItemClick = (e, i) => {
    setAnchorEl(null);
    setOpenMenu(false);
    props.setSelectedIndex(i);
  };

  const handleClose = (menuType) => {
    if (menuType === "services") {
      setServicesAnchorEl(null);
      setOpenServicesMenu(false);
    } else if (menuType === "blog") {
      setBlogAnchorEl(null);
      setOpenBlogMenu(false);
    }
  };
  
  const handleMenuClose = (menuType) => {
    setTimeout(() => {
      if (menuType === "services") {
        setServicesAnchorEl(null);
        setOpenServicesMenu(false);
      } else if (menuType === "blog") {
        setBlogAnchorEl(null);
        setOpenBlogMenu(false);
      }
    }, 200); // Small delay to prevent accidental menu closure
  };

  
  const menuOptions = [
    { name: "Services", link: "/services", activeIndex: 1, selectedIndex: 0 },
    {
      name: "Custom Software Development",
      link: "/customsoftware",
      activeIndex: 1,
      selectedIndex: 1
    },
    {
      name: "AI Business Integration",
      link: "/aiintegration",
      activeIndex: 1,
      selectedIndex: 2
    },
    {
      name: "iOS/Android App Development",
      link: "/mobileapps",
      activeIndex: 1,
      selectedIndex: 3
    },
    {
      name: "Website Development",
      link: "/websites",
      activeIndex: 1,
      selectedIndex: 4
    },
    { name: "Blog", link: "/blog", activeIndex: 5, selectedIndex: 4 }, // New Blog menu
    {
      name: "Bespoke vs Off the Shelf",
      link: "/BespokeVsOffTheShelf",
      activeIndex: 5,
      selectedIndex: 5
    },
    {
      name: "What is Business Process Management System",
      link: "/business-process-management",
      activeIndex: 5,
      selectedIndex: 6
    },
    {
      name: "Key Benefits of a Custom POS Application",
      link: "/benefits-custom-point-of-sale-application",
      activeIndex: 5,
      selectedIndex: 6
    },

    {
      name: "Custom Inventory and Stock Management Applications",
      link: "/custom-inventory-management",
      activeIndex: 5,
      selectedIndex: 6
    }
   
    
  ];
  

  const routes = [
    { name: "Home", link: "/", activeIndex: 0 },
    {
      name: "Services",
      link: "/services",
      activeIndex: 1,
      ariaOwns: anchorEl ? "simple-menu" : undefined,
      ariaPopup: anchorEl ? "true" : undefined,
      mouseOver: event => handleClick(event, "services")
    },
    { name: "The Revolution", link: "/revolution", activeIndex: 2 },
    { name: "About Us", link: "/about", activeIndex: 3 },
    { name: "Contact Us", link: "/contact", activeIndex: 4 },
    {
      name: "Blog",
      link: "/blog",
      activeIndex: 5,
      ariaOwns: anchorEl ? "simple-menu" : undefined,
      ariaPopup: anchorEl ? "true" : undefined,
      mouseOver: event => handleClick(event, "blog")
    }
  ];

  useEffect(() => {
    [...menuOptions, ...routes].forEach(route => {
      switch (window.location.pathname) {
        case `${route.link}`:
          if (props.value !== route.activeIndex) {
            props.setValue(route.activeIndex);
            if (
              route.selectedIndex &&
              route.selectedIndex !== props.selectedIndex
            ) {
              props.setSelectedIndex(route.selectedIndex);
            }
          }
          break;
        case "/estimate":
          props.setValue(5);
          break;
        default:
          break;
      }
    });
  }, [props.value, menuOptions, props.selectedIndex, routes, props]);

  const tabs = (
    <React.Fragment>
      <Tabs value={props.value} onChange={handleChange} className={classes.tabContainer} indicatorColor="primary">
  {routes.map((route, index) => (
   <Tab
   key={index}
   className={classes.tab}
   component={Link}
   to={route.link}
   label={route.name}
   aria-owns={
     route.name === "Services" && openServicesMenu
       ? "services-menu"
       : route.name === "Blog" && openBlogMenu
       ? "blog-menu"
       : undefined
   }
   aria-haspopup={route.name === "Services" || route.name === "Blog" ? "true" : undefined}
   onMouseOver={(event) => {
     if (route.name === "Services") {
       handleClick(event, "services");
     } else if (route.name === "Blog") {
       handleClick(event, "blog");
     }
   }}
 />
 
 
  ))}
</Tabs>


      <Button
        component={Link}
        to="/estimate"
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={() => props.setValue(5)}
      >
        Free Estimate
      </Button>
     <Menu
  id="simple-menu"
  anchorEl={anchorEl}
  open={Boolean(anchorEl)}  // Ensure it only opens if anchorEl exists
  onClose={handleClose}
  classes={{ paper: classes.menu }}
  MenuListProps={{
    onMouseLeave: handleClose
  }}
  elevation={0}
  style={{ zIndex: 1302 }}
  keepMounted
>
{menuOptions
  .filter(option => option.activeIndex === 5 || option.name === "Blog") // Ensure "Blog" appears
  .map((option, i) => (
    <MenuItem
      key={option.name}
      component={Link}
      to={option.link}
      classes={{ root: classes.menuItem }}
      onClick={(event) => {
        handleMenuItemClick(event, i);
        props.setValue(option.activeIndex);
        handleClose("blog");
      }}
    >
      {option.name}
    </MenuItem>
  ))}

</Menu>

<Menu
  id="services-menu"
  anchorEl={servicesAnchorEl}
  open={openServicesMenu}
  onClose={() => handleMenuClose("services")}
  classes={{ paper: classes.menu }}
  MenuListProps={{ onMouseLeave: () => handleMenuClose("services") }}
  elevation={0}
  keepMounted
  style={{ zIndex: 1500 }}  // <-- Add this
>



  {menuOptions
    .filter(option => option.activeIndex === 1 || option.name === "Services") // Ensure "Services" appears

    .map((option, i) => (
      <MenuItem
        key={option.name}
        component={Link}
        to={option.link}
        classes={{ root: classes.menuItem }}
        onClick={(event) => {
          handleMenuItemClick(event, i);
          props.setValue(option.activeIndex);
          handleClose("services");
        }}
      >
        {option.name}
      </MenuItem>
    ))}
</Menu>


<Menu
  id="blog-menu"
  anchorEl={blogAnchorEl}
  open={openBlogMenu}
  onClose={() => handleMenuClose("blog")}
  classes={{ paper: classes.menu }}
  MenuListProps={{ onMouseLeave: () => handleMenuClose("blog") }}
  elevation={0}
  keepMounted
  style={{ zIndex: 1500 }}  // <-- Add this
>


  {menuOptions
    .filter(option => option.activeIndex === 5)
    .map((option, i) => (
      <MenuItem
        key={option.name}
        component={Link}
        to={option.link}
        classes={{ root: classes.menuItem }}
        onClick={(event) => {
          handleMenuItemClick(event, i);
          props.setValue(option.activeIndex);
          handleClose("blog");
        }}
      >
        {option.name}
      </MenuItem>
    ))}
</Menu>






    </React.Fragment>
  );

  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.toolbarMargin} />
        <List disablePadding>
          {routes.map(route => (
            <ListItem
              divider
              key={`${route}${route.activeIndex}`}
              button
              component={Link}
              to={route.link}
              selected={props.value === route.activeIndex}
              classes={{ selected: classes.drawerItemSelected }}
              onClick={() => {
                setOpenDrawer(false);
                props.setValue(route.activeIndex);
              }}
            >
              <ListItemText className={classes.drawerItem} disableTypography>
                {route.name}
              </ListItemText>
            </ListItem>
          ))}
  
          {/* Blog Section */}
          <ListItem
            divider
            button
            component={Link}
            to="/blog"
            selected={props.value === 5}
            classes={{ selected: classes.drawerItemSelected }}
            onClick={() => {
              setOpenDrawer(false);
              props.setValue(5);
            }}
          >
            <ListItemText className={classes.drawerItem} disableTypography>
              Blog
            </ListItemText>
          </ListItem>
  
          {/* Blog Sub-Items */}

          <ListItem
            button
            component={Link}
            to="/business-process-management"
            selected={props.value === 5}
            classes={{ selected: classes.drawerItemSelected }}
            onClick={() => {
              setOpenDrawer(false);
              props.setValue(5);
            }}
            style={{ paddingLeft: "30px" }} // Indented for sub-menu styling
          >
            <ListItemText className={classes.drawerItem} disableTypography>
              What is Business Process Management System
            </ListItemText>
          </ListItem>


          <ListItem
            button
            component={Link}
            to="/BespokeVsOffTheShelf"
            selected={props.value === 5}
            classes={{ selected: classes.drawerItemSelected }}
            onClick={() => {
              setOpenDrawer(false);
              props.setValue(5);
            }}
            style={{ paddingLeft: "30px" }} // Indented for sub-menu styling
          >
            <ListItemText className={classes.drawerItem} disableTypography>
              Bespoke vs Off the Shelf
            </ListItemText>
          </ListItem>
  
          
  
          {/* Free Estimate Button in Drawer */}
          <ListItem
            onClick={() => {
              setOpenDrawer(false);
              props.setValue(5);
            }}
            divider
            button
            component={Link}
            classes={{
              root: classes.drawerItemEstimate,
              selected: classes.drawerItemSelected
            }}
            to="/estimate"
            selected={props.value === 5}
          >
            <ListItemText className={classes.drawerItem} disableTypography>
              Free Estimate
            </ListItemText>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <IconButton
        className={classes.drawerIconContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
    </React.Fragment>
  );
  

  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed" className={classes.appbar}>
          <Toolbar disableGutters>
            <Button
              component={Link}
              to="/"
              disableRipple
              onClick={() => props.setValue(0)}
              className={classes.logoContainer}
            >
              <img alt="company logo" className={classes.logo} src={logo} />
            </Button>
            {matches ? drawer : tabs}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  );
}
