import React from "react";
import Lottie from "react-lottie";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import vision from "../assets/vision.svg";
import CallToAction from "./ui/CallToAction";
import technologyAnimation from "../animations/technologyAnimation/data.json";

import pos from "../assets/pos.png";

const useStyles = makeStyles(theme => ({
    rowContainer: {
        paddingLeft: "5em",
        paddingRight: "5em",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "1.5em",
            paddingRight: "1.5em"
        }
    }
}));

export default function PointOfSale(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

    const defaultOptions = {
        loop: true,
        autoplay: false,
        animationData: technologyAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Grid container direction="column">
            <Grid container direction="column">
                <Grid container justifyContent="center">
                    <Grid
                        item
                        className={classes.rowContainer}
                        style={{ width: '55%', maxWidth: '55vw', textAlign: 'center' }}
                    >
                        <Typography variant="h6" paragraph>
                            Key Benefits of a Custom POS Application
                        </Typography>


                        {/* Display Image Here */}
                        <img
                            src={pos}
                            alt="Custom POS Application Developer"
                            style={{ width: "100%", maxWidth: "500px", marginTop: "20px" }}
                        />

<Typography variant="h6" paragraph>
  A custom point of sale (POS) application is a tailor-made software solution specifically developed to meet the unique business requirements of a retailer, restaurant, or any other establishment that sells goods or services directly to customers. Unlike off-the-shelf POS software, custom solutions offer businesses an unparalleled level of flexibility, scalability, and integration capabilities, making them ideal for addressing specific challenges, streamlining operations, and enhancing customer experiences.
</Typography>

<Typography variant="h6" paragraph>
  Why Choose a Custom Point of Sale Application?
</Typography>

<Typography variant="h6" paragraph>
  A custom POS application allows businesses to personalize their software according to their workflow, brand identity, and operational needs. This adaptability ensures a smoother checkout process, efficient inventory management, comprehensive reporting, and superior customer relationship management. Businesses no longer need to settle for generic solutions that don't align perfectly with their operational processes.
</Typography>

<Typography variant="h6" paragraph>
  Key Benefits of a Custom POS Application
</Typography>

<Typography variant="h6" paragraph>
  1. Enhanced Efficiency and Productivity
  A custom POS application significantly reduces transaction times, automates inventory management, and optimizes employee workflows. The ability to tailor functionalities, like barcode scanning, RFID integrations, and payment methods, specifically to the business ensures seamless daily operations.
</Typography>

<Typography variant="h6" paragraph>
  2. Improved Inventory Management
  Custom POS solutions include advanced inventory management capabilities tailored exactly to business requirements, allowing real-time tracking, automated reordering, and advanced analytics. This prevents stockouts, reduces excess inventory, and saves costs through precise inventory forecasting.
</Typography>

<Typography variant="h6" paragraph>
  3. Personalized Customer Experiences
  With a custom POS, businesses can integrate loyalty programs, personalized offers, and comprehensive customer data analytics, enhancing customer satisfaction and retention rates. Tailored customer interactions significantly improve overall business performance and customer lifetime value.
</Typography>

<Typography variant="h6" paragraph>
  4. Scalability and Flexibility
  Custom POS systems are designed with scalability in mind, ensuring the solution grows and adapts as the business expands. Whether opening new branches or introducing new products and services, businesses can swiftly modify their POS without disruption.
</Typography>

<Typography variant="h6" paragraph>
  5. Advanced Integration Capabilities
  Custom applications are inherently built to integrate seamlessly with existing business software, including accounting systems, CRM solutions, and e-commerce platforms. This interconnected approach streamlines business operations, reduces manual data entry, and minimizes errors.
</Typography>

<Typography variant="h6" paragraph>
  6. Robust Security
  Custom-built POS solutions enable businesses to implement advanced security measures specific to their needs. Encryption, secure payment gateways, and enhanced authentication protocols safeguard sensitive financial and customer information effectively, preventing fraud and cyber threats.
</Typography>

<Typography variant="h6" paragraph>
  Developing a Custom Point of Sale Application
</Typography>

<Typography variant="h6" paragraph>
  Step 1: Requirement Analysis
  Before development begins, a thorough understanding of the business’s needs, current pain points, and objectives is crucial. This involves discussions with stakeholders, analyzing operational processes, and determining the essential functionalities needed.
</Typography>

<Typography variant="h6" paragraph>
  Step 2: Planning and Design
  Once the requirements are clear, designing the application's architecture is the next step. UI/UX design focuses on creating intuitive interfaces that enhance user interactions. Detailed prototypes help visualize the final product and refine functionalities before actual development.
</Typography>

<Typography variant="h6" paragraph>
  Step 3: Development
  During development, developers create custom modules, integrate payment gateways, and ensure smooth integration with existing software solutions. Agile methodologies are typically used, allowing iterative progress and continuous feedback.
</Typography>

<Typography variant="h6" paragraph>
  Step 4: Testing and Quality Assurance
  Testing is essential to identify bugs, ensure security standards, and validate overall application performance. Rigorous testing procedures, including functional testing, load testing, security audits, and usability testing, are carried out to ensure the POS system is reliable and secure.
</Typography>

<Typography variant="h6" paragraph>
  Step 5: Deployment
  Deploying the custom POS application requires careful planning to minimize downtime and disruptions. This involves installation on relevant hardware, data migration from legacy systems, employee training, and post-deployment support.
</Typography>

<Typography variant="h6" paragraph>
  Step 6: Maintenance and Support
  Post-deployment, continuous support and regular updates are essential. Maintenance services ensure the application stays current with evolving technologies, security standards, and changing business needs.
</Typography>

<Typography variant="h6" paragraph>
  Industries Benefiting from Custom POS Solutions
</Typography>

<Typography variant="h6" paragraph>
  Retail
  Custom POS applications in retail streamline checkout processes, manage promotions, facilitate returns, and optimize stock management. Retail businesses benefit significantly from personalized software that adapts to their specific inventory handling, customer loyalty schemes, and detailed sales analytics.
</Typography>

<Typography variant="h6" paragraph>
  Restaurants
  Restaurants rely heavily on custom POS systems to manage orders, reservations, table management, menu customization, and kitchen coordination. Tailored applications enhance operational efficiency, reduce errors, and improve customer service by providing faster and more accurate order processing.
</Typography>

<Typography variant="h6" paragraph>
  Hospitality
  Hotels, resorts, and hospitality establishments utilize custom POS applications to manage guest bookings, room services, and billing efficiently. Integrated applications ensure smooth operations, enhance guest experiences, and increase customer loyalty through personalized services and simplified transaction processes.
</Typography>

<Typography variant="h6" paragraph>
  Healthcare
  Pharmacies and healthcare providers benefit from custom POS systems through precise inventory management, prescription tracking, and compliance with regulatory requirements. Customized applications ensure accuracy, regulatory compliance, and patient safety.
</Typography>

<Typography variant="h6" paragraph>
  Future Trends in Custom POS Applications
</Typography>

<Typography variant="h6" paragraph>
  Mobile POS
  Mobile POS (mPOS) solutions are becoming increasingly popular, providing businesses with increased mobility, quicker transactions, and better customer interactions. Customized mobile POS systems allow businesses to process sales anywhere, enhancing convenience and operational flexibility.
</Typography>

<Typography variant="h6" paragraph>
  Conclusion
  Investing in a custom point of sale application provides businesses with a significant competitive edge by addressing unique operational needs, enhancing efficiency, and improving customer interactions. Custom POS systems are future-proof solutions, offering scalability, security, and comprehensive integration capabilities, making them indispensable tools in modern business management.
</Typography>

                        

                       
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <CallToAction setValue={props.setValue} />
            </Grid>
        </Grid>
    );
}
